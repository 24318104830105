import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Input, Button } from '@StixNext/mojito-ui';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import axios from 'axios';
import { EAlertType } from '../common/Snackbars';
import { auditLogger, EAUDIT_COLLEACTION, EAUDIT_TYPE } from '../../utils/Utils';

const API_URL = process.env.REACT_APP_API_URL;
interface ICollectionProps {
  open: boolean;
  tenantId: string | number;
  isSuccessCall: boolean;
  handleClose: () => void;
  setForms: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
  setRelations: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessCall: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertOptions: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      message: string;
      type: EAlertType;
    }>
  >;
}

const Collection = (props: ICollectionProps) => {
  const { open, tenantId, isSuccessCall, handleClose, setForms, setRelations, setOpen, setSuccessCall, setAlertOptions } = props;
  const [title, setTitle] = useState('');
  const [titleText, setTitleText] = useState('');
  const [collectionType, setCollectionTypeChange] = useState('collection_model');
  const handleTitleChange = (title: string) => {
    setTitle(title);
  };
  const handleTitleTextChange = (title: string) => {
    setTitleText(title);
  };
  const handleCollectionTypeChange = (type: string) => {
    setCollectionTypeChange(type);
  };
  const handleSaveCollection = () => {
    let url = '';
    if (collectionType === 'collection_model') {
      url = `${API_URL}/forms`;
    } else {
      url = `${API_URL}/relations`;
    }
    const data = {
      title,
      type: 'object',
      required: [],
      properties: {},
      tenant: tenantId,
      titleText: titleText,
    };
    axios
      .post<never>(url, data)
      .then((response) => {
        if (collectionType === 'collection_model') {
          setForms([response.data]);
        } else {
          setRelations([response.data]);
        }
        setOpen(false);
        setTitle('');
        setTitleText('');
        setSuccessCall(!isSuccessCall);
        setAlertOptions({ isOpen: true, message: 'Changes saved!', type: EAlertType.SUCCESS });
      })
      .catch((error) => {
        setAlertOptions({ isOpen: true, message: 'Failed to update! Try again', type: EAlertType.ERROR });
        console.log(error);
      });
    // AUDIT LOG
    auditLogger(EAUDIT_TYPE.CREATE, EAUDIT_COLLEACTION.FORMS, JSON.stringify({}), JSON.stringify(data));
  };
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create New Collection</DialogTitle>
      <DialogContent>
        <DialogContentText>A New collection will be created!</DialogContentText>
        <div title="Collection which will be used by api">
          <Input
            type="input"
            textarea={false}
            cols="50"
            rows="10"
            disabled={false}
            error={false}
            value={title}
            onChange={(event: { target: { value: string } }) => handleTitleChange(event.target.value)}
            validationCaption="Enter a valid title"
            placeholder="End Point(camelCase)"
          />
        </div>
        <div title="Title which will be used by clients" style={{ paddingTop: '15px' }}>
          <Input
            type="input"
            textarea={false}
            cols="50"
            rows="10"
            disabled={false}
            error={false}
            value={titleText}
            onChange={(event: { target: { value: string } }) => handleTitleTextChange(event.target.value)}
            validationCaption="Enter a valid title"
            placeholder="Title"
          />
        </div>
        <div style={{ paddingTop: '20px' }}>
          <InputLabel id="demo-simple-select-label">Collection type</InputLabel>
          <Select
            style={{ width: '100%' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={collectionType}
            onChange={(event) => handleCollectionTypeChange(event.target.value as string)}
          >
            <MenuItem value="collection_model">Collection Type</MenuItem>
            <MenuItem value="relation_model">Relation Model</MenuItem>
          </Select>
        </div>
      </DialogContent>
      <DialogActions>
        <Button primary onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button primary onClick={handleSaveCollection} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Collection as typeof Collection;
