import axios from 'axios';
import keycloak from '../utils/keycloak.js';
const REPORT_URL = process.env.REACT_APP_REPORT_URL;

function loadSalesData(data) {
  const { paging, pageIndex, clientId } = data;
  return new Promise((resolve, reject) => {
    axios
      .post(REPORT_URL + '/sales/byClient', { clientid: clientId })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function refundTxn(data) {
  const { clientId, txnid } = data;
  const user = {
    id: keycloak.tokenParsed.sub,
    email: keycloak.tokenParsed.email,
    firstname: keycloak.tokenParsed.given_name,
    lastname: keycloak.tokenParsed.family_name,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(REPORT_URL + '/payments/braintree/return', { clientid: clientId, txnid, user })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function resendEmail(data) {
  const { clientId, transaction } = data;
  return new Promise((resolve, reject) => {
    axios
      .post(REPORT_URL + '/queues/addJob', {
        jobType: 'email',
        ...transaction,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function updateEmail(data) {
  const { clientId, transaction } = data;
  return new Promise((resolve, reject) => {
    axios
      .post(REPORT_URL + '/transactions/changeEmailAddress', {
        ...transaction,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function loadCollectionsData(data) {
  const { paging, pageIndex, clientId } = data;
  return new Promise((resolve, reject) => {
    axios
      .post(REPORT_URL + '/sales/collectionsByClient', { clientid: clientId })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function loadOfferCodesData(data) {
  const { paging, pageIndex, clientId } = data;
  return new Promise((resolve, reject) => {
    axios
      .post(REPORT_URL + '/offers/offerCodesByClient', { clientid: clientId })
      //
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function txnInfo(data) {
  const { clientId, txnid } = data;
  return new Promise((resolve, reject) => {
    axios
      .get('/payments/braintree/info/' + txnid)

      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export default {
  refundTxn,
  loadSalesData,
  loadCollectionsData,
  txnInfo,
  loadOfferCodesData,
  resendEmail,
  updateEmail,
};
