import React, { useEffect, useState } from 'react';
import { Button, Select, FormControl, MenuItem, TextField } from '@material-ui/core';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import Snackbars, { EAlertType } from '../common/Snackbars';

const API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_REPORT_URL = process.env.REACT_APP_REPORT_URL;

const OfferCodeGeneration = () => {
  const axiosConfig = {
    headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` },
  };
  const history = useHistory();

  let { clientId, roomId } = useParams() as { clientId: string | number; roomId: string | number };
  // local state

  const [offerDetails, setofferDetails] = useState({ reference: '', expires: '', amount: '', count: '', product: '' });
  const offerdetailHandler = (e) => {
    let newDetails = { ...offerDetails };
    newDetails[e.target.name] = e.target.value;
    setofferDetails(newDetails);
  };

  const [alertOptions, setAlertOptions] = useState({ isOpen: false, message: '', type: EAlertType.INFO });
  const onAlertCloseHandler = () => {
    setAlertOptions({ isOpen: false, message: '', type: EAlertType.INFO });
  };

  const [productDetails, setProductDetails] = useState<Record<string, any>[]>([]);
  useEffect(() => {
    axios
      .get(`${API_URL}/contents?collection=products&tenant=${clientId}`, axiosConfig)
      .then((response) => {
        setProductDetails(response.data.data);
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  }, [clientId]);

  // handlers
  let makeValuesToString = (item) => {
    let itemDetail = item;
    Object.keys(item).forEach(function (key) {
      typeof item[key] == 'object' ? (item[key] = JSON.stringify(item[key])) : (item[key] = String(item[key]));
    });
    return itemDetail;
  };

  const onCancelClickHandler = () => {
    history.goBack();
  };

  const generateOfferCode = () => {
    const url = REACT_APP_REPORT_URL + '/offers/batchCreate';
    const user = JSON.parse(window.localStorage.getItem('user') as string);
    const selectedProduct = productDetails.find((product) => product.id === offerDetails.product);
    const ProductTostring = makeValuesToString(selectedProduct);
    let { reference, expires, amount, count } = offerDetails;
    let expires_utc = new Date(expires).toUTCString();
    const data = {
      user: {
        id: user.jti,
        email: user.email,
        firstname: user.given_name,
        lastname: user.family_name,
      },
      client: clientId,
      product: ProductTostring,
      reference,
      expires: expires_utc,
      amount,
      count,
      product_based: true,
      payment_type: 'BankTransfer',
      payment_method: 'Cash',
      payment_source: 'Bank',
    };
    axios
      .post(url, data, axiosConfig)
      .then((response) => {
        setAlertOptions({ isOpen: true, message: 'Offer code generated!', type: EAlertType.SUCCESS });
        setTimeout(() => {
          history.push(`/offerCodes/${clientId}`);
        }, 500);
      })
      .catch((error) => {
        setAlertOptions({ isOpen: true, message: 'Failed to generate! Try again', type: EAlertType.ERROR });
        console.log(error);
      });
  };

  const onGenerateClickHandler = () => {
    if (offerDetails.reference === '') {
      setAlertOptions({ isOpen: true, message: 'Reference is required', type: EAlertType.ERROR });
      return;
    }
    if (offerDetails.expires === '') {
      setAlertOptions({ isOpen: true, message: 'Expiry date is required', type: EAlertType.ERROR });
      return;
    }
    if (offerDetails.amount === '') {
      setAlertOptions({ isOpen: true, message: 'Amount is required', type: EAlertType.ERROR });
      return;
    }
    if (offerDetails.count === '') {
      setAlertOptions({ isOpen: true, message: 'Count is required', type: EAlertType.ERROR });
      return;
    }
    if (offerDetails.product === '') {
      setAlertOptions({ isOpen: true, message: 'Product is required', type: EAlertType.ERROR });
      return;
    }
    generateOfferCode();
  };

  return (
    <div className="container-wrapper">
      {alertOptions.isOpen && (
        <Snackbars isOpen={alertOptions.isOpen} message={alertOptions.message} type={alertOptions.type} openCloseHandler={onAlertCloseHandler} />
      )}
      <div className="container-title">
        <h1>{'Generate Offer Code'}</h1>
      </div>
      <div className="watch-party-form-container">
        {/* FOR OFFER */}

        <div className="watch-party-form">
          <div className="watch-party-form-element">
            <label>
              Reference <sup>*</sup>
            </label>
            <TextField
              id="outlined-basic"
              label="Reference"
              variant="outlined"
              name="reference"
              value={offerDetails?.reference}
              onChange={offerdetailHandler}
            />
          </div>
          <div className="watch-party-form-element">
            <label>
              Expires <sup>*</sup>
            </label>
            <TextField
              id="datetime-local"
              type="datetime-local"
              name="expires"
              value={offerDetails?.expires}
              defaultValue={''}
              style={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={offerdetailHandler}
            />
          </div>
        </div>
        <div className="watch-party-form">
          <div className="watch-party-form-element">
            <label>
              Product <sup>*</sup>
            </label>
            <FormControl variant="outlined">
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="product"
                value={offerDetails?.product}
                onChange={offerdetailHandler}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productDetails.length > 0 &&
                  productDetails.map((product) => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.id}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="watch-party-form-element">
            <label>
              Amount <sup>*</sup>
            </label>
            <TextField
              id="outlined-basic"
              label="Amount"
              variant="outlined"
              name="amount"
              value={offerDetails?.amount}
              onChange={offerdetailHandler}
            />
          </div>
        </div>
        <div className="watch-party-form">
          <div className="watch-party-form-element">
            <label>
              Count <sup>*</sup>
            </label>
            <TextField id="outlined-basic" label="Count" variant="outlined" name="count" value={offerDetails?.count} onChange={offerdetailHandler} />
          </div>
        </div>
        <div className="container-underline" style={{ marginTop: '20px', marginBottom: '20px' }}></div>
        <div className="container-form-submit">
          <>
            <div>
              <Button variant="outlined" onClick={() => onCancelClickHandler()}>
                Cancel
              </Button>
            </div>
            <div style={{ paddingLeft: '20px' }}>
              <Button variant="contained" color="inherit" onClick={() => onGenerateClickHandler()}>
                {'Generate'}
              </Button>
            </div>
          </>
        </div>

        {/* FOR OFFER */}
      </div>
    </div>
  );
};

export default OfferCodeGeneration;
