import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import SearchBar from 'material-ui-search-bar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// Custom components
import { Button as STXButton } from '@StixNext/mojito-ui';
import Editor from '../editor/Editor';
import Breadcrumb from '../common/Breadcrumb';
import CustomAccordion from '../common/CustomAccordion';
// sistic UI

import { useHistory, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import axios from 'axios';
import Snackbars, { EAlertType } from '../common/Snackbars';
import DeleteAction from '../common/DeleteAction';
import { cloneDeep, upperFirst } from 'lodash';
import { auditLogger, EAUDIT_TYPE, EAUDIT_COLLEACTION } from '../../utils/Utils';

const API_URL = process.env.REACT_APP_API_URL;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#5c5c5c',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {},
  paperElement: {
    margin: '20px',
    border: '1px solid',
    borderRadius: '6px',
  },
  accordionDiv: {
    width: '20%',
    float: 'left',
    border: '1px solid black',
    minHeight: '100vh',
  },
  bodyDiv: {
    minHeight: '100vh',
    margin: '20px',
    display: 'flex',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 999999,
  },
  createGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px',
    marginRight: '20px',
  },
  createContent: {
    border: '1px solid black',
    width: '78%',
    minHeight: '100vh',
    marginLeft: '20px',
  },
  mainContent: {
    display: 'flex',
  },
  tableContainer: {
    margin: '10px',
  },
  aTag: {
    textDecoration: 'none !important',
    color: 'black',
    '&:hover': {
      color: 'blue',
      textDecoration: 'none !important',
      cursor: 'pointer',
    },
  },
  editorDiv: {
    margin: '20px',
    border: '1px solid black',
  },
  savePublish: {
    display: 'flex',
    flexFlow: 'row-reverse',
    marginTop: '10px',
    marginRight: '20px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  searchContainer: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' },
  searchBar: {
    width: '85%',
  },
}));

const updateSelectedData = (
  data: Record<string, any>,
  relations: Record<string, any>[],
  setSelectedEditor: React.Dispatch<React.SetStateAction<Record<string, any>>>,
) => {
  Object.keys(data.properties).forEach((propKey) => {
    if (data.properties[propKey].type === 'relation') {
      const relation = relations.find((relation) => relation.title === data.properties[propKey].relationName);
      if (relation) {
        axios
          .get(`${API_URL}/relational-contents?collection_id=${relation._id}`)
          .then((response) => {
            if (response.data.data) {
              const optionsEnum: string[] = [];
              const optionsEnamNames: string[] = [];
              response.data.data.forEach((eachData: Record<string, any>) => {
                optionsEnamNames.push(eachData.value);
                optionsEnum.push(eachData.key);
              });
              if (data.properties[propKey].relationType === 'one_to_one') {
                data.properties[propKey].type = 'string';
                data.properties[propKey].enum = optionsEnum;
                data.properties[propKey].enumNames = optionsEnamNames;
              } else {
                data.properties[propKey].type = 'array';
                data.properties[propKey].uniqueItems = true;
                data.properties[propKey].items = {
                  type: 'string',
                  enum: optionsEnum,
                  enumNames: optionsEnamNames,
                };
              }
              setSelectedEditor(data);
            }
          })
          .catch((error) => {
            console.log(error, 'error');
          });
      }
    }
  });
};

function ContentBuilder(props) {
  axios.defaults.headers.common.Authorization = `Bearer ${window.localStorage.getItem('token')}`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` },
  };
  const { roles } = props;
  let { tenantId } = useParams() as { tenantId: string | number };
  const history = useHistory();
  const [expanded, setExpanded] = useState('panel1');
  const [forms, setForms] = useState<Record<string, any>[]>([]);
  const [relations, setRelations] = useState<Record<string, any>[]>([]);
  const [contents, setContents] = useState<Record<string, any>[]>([]);
  const [selectedForm, setSelectedForm] = useState('0');
  const [collectionName, setCollectionName] = useState('');
  const [selectedEditor, setSelectedEditor] = useState<Record<string, any>>({});
  const [formData, setFormData] = useState({});
  const [isFieldOpen, setFieldOpen] = useState(false);
  const [collectionType, setCollectionTypeChange] = useState('collection_model');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isSuccessCall, setSuccessCall] = useState(false);
  const [searched, setSearched] = useState<string>('');
  const [searchBy, setSearchBy] = useState('');
  const [menuList, setMenuList] = useState<{ key: string; value: string }[]>([{ key: 'Select search key', value: 'search_key' }]);
  const [filteredContents, setFilteredContents] = useState<Record<string, any>[]>([]);
  const [alertOptions, setAlertOptions] = useState({ isOpen: false, message: '', type: EAlertType.INFO });
  const classes = useStyles();

  const onAlertCloseHandler = () => {
    setAlertOptions({ isOpen: false, message: '', type: EAlertType.INFO });
  };

  const handleChange = (panel: string) => (_: React.ChangeEvent<HTMLInputElement>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : 'false');
  };

  const handleSelectedForm = (index: string, type: string, name: string) => {
    setSelectedForm(index);
    setCollectionName(name);
    setCollectionTypeChange(type);
    setFieldOpen(false);
  };

  const handleFieldOpen = () => {
    setFormData({});
    setFieldOpen(true);
  };

  const handleFieldClose = () => {
    setFieldOpen(false);
  };

  useEffect(() => {
    let selectedData: Record<string, any> = {};
    const type = collectionType ?? 'collection_type';
    const index = selectedForm ?? 0;
    if (type === 'collection_type') {
      axios
        .get(`${API_URL}/contents?collection_id=${index}&previewMode=true`)
        .then((response) => {
          const menuOptions: { key: string; value: string }[] = [];
          if (response.data.data.length > 0) {
            Object.keys(response.data.data[0]).forEach((keys, index) => {
              if (keys !== '_id' && index < 5) {
                menuOptions.push({ key: keys, value: keys.replace('_', ' ').toUpperCase() });
              }
            });
            setMenuList(menuOptions);
          }
          setContents(response.data.data);
          setFilteredContents(response.data.data);
        })
        .catch((error) => {
          console.log(error, 'error');
        });
      selectedData = forms.find((form) => form._id === index) as Record<string, any>;
      updateSelectedData(selectedData, relations, setSelectedEditor);
    } else {
      axios
        .get(`${API_URL}/relational-contents?collection_id=${index}`)
        .then((response) => {
          const menuOptions: { key: string; value: string }[] = [];
          if (response.data.data.length > 0) {
            Object.keys(response.data.data[0]).forEach((keys, index) => {
              if (keys !== '_id' && index < 5) {
                menuOptions.push({ key: keys, value: keys.replace('_', ' ').toUpperCase() });
              }
            });
            setMenuList(menuOptions);
          }
          setContents(response.data.data);
          setFilteredContents(response.data.data);
        })
        .catch((error) => {
          console.log(error, 'error');
        });
      selectedData = relations.find((relation) => relation._id === index) as Record<string, any>;
    }
    setSelectedEditor(selectedData);
  }, [selectedForm, isSuccessCall, tenantId, alertOptions]);

  useEffect(() => {
    axios
      .get(`${API_URL}/relations?tenant=${tenantId}`)
      .then((response) => {
        setRelations(response.data.data);
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  }, [tenantId]);

  useEffect(() => {
    axios
      .get(`${API_URL}/forms?tenant=${tenantId}`)
      .then((response) => {
        const data = response.data.data;
        if (roles.includes('DCH_SUPER_ADMIN')) {
          setForms(data);
        } else {
          if (data.length > 0) {
            const filteredData = data.filter((eachData) => eachData.title !== 'Clients');
            setForms(filteredData);
          } else {
            setForms(data);
          }
        }
        setSelectedEditor(data[0]);
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  }, [relations, tenantId]);

  const path = [
    {
      text: 'Home',
      url: '/',
    },
    {
      text: 'CMS',
      url: '/cms',
    },
  ];

  const onSubmit = (data: Record<string, any>, isEdit: boolean) => {
    data.tenant = tenantId;
    data.collection_id = selectedForm;
    data.collection = collectionName;
    let url = '';
    if (collectionType === 'collection_type') {
      url = `${API_URL}/contents/`;
    } else {
      url = `${API_URL}/relational-contents/`;
    }
    if (isEdit) {
      const originalContent = contents.find((content) => content._id === data._id);
      delete data.preview;
      if (originalContent) {
        originalContent.preview = data;
        originalContent.preview.isPublished = false;
        axios
          .put(url + data._id, originalContent, axiosConfig)
          .then((response) => {
            setAlertOptions({ isOpen: true, message: 'Changes saved!', type: EAlertType.SUCCESS });
            setFieldOpen(false);
            setSuccessCall(!isSuccessCall);
            if (response?.data?.collection === 'products') history.push(`/productMapping/${tenantId}/${response.data._id}`);
          })
          .catch((error) => {
            setAlertOptions({ isOpen: true, message: 'Failed to update! Try again', type: EAlertType.ERROR });
            console.log(error);
          });
      }
    } else {
      data.isPublished = false;
      const newData = cloneDeep(data);
      newData.preview = data;
      newData.preview.isPublished = false;
      axios
        .post(url, newData, axiosConfig)
        .then((response) => {
          setAlertOptions({ isOpen: true, message: 'Changes saved!', type: EAlertType.SUCCESS });
          setFieldOpen(false);
          setSuccessCall(!isSuccessCall);
          if (response?.data?.collection === 'products') history.push(`/productMapping/${tenantId}/${response.data._id}`);
        })
        .catch((error) => {
          setAlertOptions({ isOpen: true, message: 'Failed to update! Try again', type: EAlertType.ERROR });
          console.log(error);
        });
    }
  };

  const deleteContents = (id: string, deletedData: Record<string, any>) => {
    let url = '';
    if (collectionType === 'collection_type') {
      url = `${API_URL}/contents/`;
    } else {
      url = `${API_URL}/relational-contents/`;
    }
    axios
      .delete(url + id)
      .then((response) => {
        setAlertOptions({ isOpen: true, message: 'Changes saved!', type: EAlertType.SUCCESS });
        setSuccessCall(!isSuccessCall);
      })
      .catch((error) => {
        setAlertOptions({ isOpen: true, message: 'Failed to update! Try again', type: EAlertType.ERROR });
        console.log(error);
      });
    // AUDIT LOG
    auditLogger(EAUDIT_TYPE.DELETE, EAUDIT_COLLEACTION.CONTENTS, JSON.stringify(deletedData), JSON.stringify({}));
  };

  const editContents = (formData: Record<string, any>) => {
 
    if (formData.preview) {
      formData.preview._id = formData._id;
    }
    if (formData.preview && formData.preview.isPublished === false) {
      setFormData(formData.preview);
    } else {
      setFormData(formData);
    }
    setFieldOpen(true);
   
  };

  const onCancel = () => {
    setFieldOpen(false);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  

 
 
const allSearchFunction=(searchedVal:string)=>{
  if(selectedEditor.title=="Programes"){
    const program_title_array = contents.filter((item)=> item?.preview?.title?.toUpperCase().includes(searchedVal.toUpperCase()));
    const programe_channelId_array = contents.filter((item)=> item?.preview?.channelId?.toUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const program_id_array = contents.filter((item)=> item?.preview?.id?.toUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const program_writeUp_array= contents.filter((item)=> item?.preview?.writeUp?.toUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const finalarray = program_title_array.concat(programe_channelId_array,program_id_array,program_writeUp_array);
      setFilteredContents(finalarray);
   
     
  }else if(selectedEditor.title=="products"){
  
    const product_title_array = contents.filter((item)=> item?.preview?.title?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
    const product_expireOn_array = contents.filter((item)=> item?.preview?.expiresOn?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const product_id_array = contents.filter((item)=> item?.preview?.id?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const product_description_array= contents.filter((item)=> item?.preview?.description?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const finalarray = product_title_array.concat(product_expireOn_array,product_id_array,product_description_array);
     setFilteredContents(finalarray);
  }
  else if(selectedEditor.title=="overview"){
    const overview_videoURL_array = contents.filter((item)=> item?.preview?.videoURL?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
    const overview_ftHeadingOne_array = contents.filter((item)=> item?.preview?.ftHeadingOne?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const overview_ftHeadingTwo_array = contents.filter((item)=> item?.preview?.ftHeadingTwo?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const overview_ftHeadingThree_array = contents.filter((item)=> item?.preview?.ftHeadingThree?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const finalarray = overview_videoURL_array.concat(overview_ftHeadingOne_array,overview_ftHeadingTwo_array,overview_ftHeadingThree_array);
     setFilteredContents(finalarray);
  }
  else if(selectedEditor.title=="chatProperties"){
 
    const chatProperties_reservedNames_array = contents.filter((item)=> item?.preview?.reservedNames?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
    const chatProperties_blockedNames_array = contents.filter((item)=> item?.preview?.blockedNames?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const chatProperties_collection_id_array = contents.filter((item)=> item?.preview?.collection_id?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const chatProperties_tenant_array = contents.filter((item)=> item?.preview?.tenant?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const finalarray = chatProperties_reservedNames_array.concat(chatProperties_blockedNames_array,chatProperties_collection_id_array,chatProperties_tenant_array);
     setFilteredContents(finalarray);
  }
  else if(selectedEditor.title=="shortCodeProperties"){
    const shortCodeProperties_code_array = contents.filter((item)=> item?.preview?.code?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
    const shortCodeProperties_link_array = contents.filter((item)=> item?.preview?.link?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const shortCodeProperties_target_array = contents.filter((item)=> item?.preview?.target?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const shortCodeProperties_tenant_array = contents.filter((item)=> item?.preview?.tenant?.toLocaleUpperCase().includes(searchedVal.toLocaleUpperCase()));
     const finalarray = shortCodeProperties_code_array.concat(shortCodeProperties_link_array,shortCodeProperties_target_array,shortCodeProperties_tenant_array);
     setFilteredContents(finalarray);
  }    

}

  const requestSearch = (searchedVal: string) => {
    if(!searchBy){
      allSearchFunction(searchedVal)
    }else{
    if (searchedVal === '') {
      setFilteredContents(contents);
    } else {
      const filteredRows = contents.filter((row) => {
        return row[searchBy]?.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setFilteredContents(filteredRows);
      }
    }
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handlePreview = (formData: Record<string, any>) => {
    const clientDetails: any = window.localStorage.getItem('clientDetails');
    const { site_url: clientUrl } = JSON.parse(clientDetails ?? '') ?? '';
    let category = formData.category?.toLowerCase();
    if (JSON.parse(clientDetails)?.value == 'SSOLOUNGE') {
      category = 'watch';
    } else if (category === 'livenow') {
      category = 'upcoming';
    } else if (category === 'playback') {
      category = 'playback';
    }
    category += `/${formData.id}`;
    window.open(`${clientUrl}/${category}/?inPreviewMode=true`, '_blank');
  };

  const handlePublishChanges = (content: Record<string, any>) => {
    if (content.preview && content.preview.isPublished === false) {
      const newContent = cloneDeep(content.preview);
      newContent.isPublished = true;
      delete content.preview;
      newContent.preview = content;
      newContent.preview.isPublished = true;
      let url = '';
      if (collectionType === 'collection_type') {
        url = `${API_URL}/contents/`;
      } else {
        url = `${API_URL}/relational-contents/`;
      }
      axios
        .put(url + content._id, newContent)
        .then((response) => {
          setAlertOptions({ isOpen: true, message: 'Changes published!', type: EAlertType.SUCCESS });
        })
        .catch((error) => {
          setAlertOptions({ isOpen: true, message: 'Failed to update! Try again', type: EAlertType.ERROR });
          console.log(error);
        });
      // AUDIT LOG
      const newData = cloneDeep(newContent);
      delete newData.preview;
      auditLogger(EAUDIT_TYPE.UPDATE, EAUDIT_COLLEACTION.CONTENTS, JSON.stringify(content), JSON.stringify(newData));
    }
  };

  const ignoreKeys = ['_id', 'id', 'writeUp', 'channelId'];
  return (
    <div>
      <Breadcrumb path={path} title="Contents" />
      {alertOptions.isOpen && (
        <Snackbars isOpen={alertOptions.isOpen} message={alertOptions.message} type={alertOptions.type} openCloseHandler={onAlertCloseHandler} />
      )}
      <div className={classes.paperElement}>
        <Paper variant="outlined" elevation={0}>
          <div className={classes.bodyDiv}>
            <div className={classes.accordionDiv}>
              <CustomAccordion
                name="panel1"
                type="collection_type"
                title="Contents"
                items={forms}
                expanded={expanded}
                selectedForm={selectedForm}
                handleChange={handleChange}
                handleSelectedForm={handleSelectedForm}
              />
              {roles.includes('DCH_SUPER_ADMIN') && (
                <CustomAccordion
                  name="panel2"
                  type="relation_type"
                  title="Relational Contents"
                  items={relations}
                  expanded={expanded}
                  selectedForm={selectedForm}
                  handleChange={handleChange}
                  handleSelectedForm={handleSelectedForm}
                />
              )}
            </div>
            <div className={classes.createContent}>
              {!isFieldOpen ? (
                <div className={classes.createGroup}>
                  <STXButton primary onClick={handleFieldOpen}>
                    Add
                  </STXButton>
                </div>
              ) : (
                ''
              )}
              <div className={classes.editorDiv}>
                {isFieldOpen ? (
                  <Editor data={selectedEditor} onSubmit={onSubmit} formData={formData} onCancel={onCancel} />
                ) : (
                  <div className={classes.tableContainer}>
                    <div className={classes.searchContainer}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Search By</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={searchBy}
                          onChange={handleSearchByChange}
                        >
                          {menuList.map((menu) => {
                            return <MenuItem value={menu.key}>{menu.value}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                      <SearchBar
                        className={classes.searchBar}
                        value={searched}
                        onChange={(searchVal) => requestSearch(searchVal)}
                        onCancelSearch={() => cancelSearch()}
                      />
                    </div>
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            {/* <StyledTableCell>ID</StyledTableCell> */}
                            {filteredContents.length > 0 && filteredContents[0]?.thumbNail && <StyledTableCell>Thumbnail</StyledTableCell>}
                            {filteredContents.length > 0 &&
                              Object.keys(filteredContents[0]).map((keys, index) =>
                                !ignoreKeys.includes(keys) && index < 5 ? (
                                  <StyledTableCell>{upperFirst(keys.replace('_', ' '))}</StyledTableCell>
                                ) : (
                                  ''
                                ),
                              )}
                            <StyledTableCell>Action</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredContents.length > 0 &&
                            filteredContents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value, index) => (
                              <StyledTableRow key={index}>
                                {value.thumbNail && (
                                  <StyledTableCell>
                                    <img src={value.thumbNail.replace('data:image/gif;base64,', '')} width={'100%'} height={200} />
                                  </StyledTableCell>
                                )}
                                {Object.keys(filteredContents[0]).map((keys, index) =>
                                  !ignoreKeys.includes(keys) && index < 5 ? <StyledTableCell>{parse(`${value[keys]}`)}</StyledTableCell> : '',
                                )}
                                <StyledTableCell>
                                  <div>
                                    <Button variant="outlined" startIcon={<EditOutlinedIcon />} onClick={() => editContents(value)}>
                                      Edit
                                    </Button>
                                  </div>
                                  <div style={{ marginTop: '10px' }}>
                                    <Button
                                      variant="outlined"
                                      startIcon={<DeleteAction onDeleteClickHandler={deleteContents} url={value._id} deletedData={value} />}
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                  {value.collection === 'Programes' && (
                                    <div style={{ marginTop: '10px' }}>
                                      <Button variant="outlined" startIcon={<VisibilityOutlinedIcon />} onClick={() => handlePreview(value)}>
                                        Preview
                                      </Button>
                                    </div>
                                  )}
                                  {value.preview?.isPublished === false && (
                                    <div style={{ marginTop: '10px' }}>
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<PublishOutlinedIcon />}
                                        onClick={() => handlePublishChanges(value)}
                                      >
                                        Publish
                                      </Button>
                                    </div>
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      component="div"
                      count={((contents && contents.length > 0 && contents.length) ?? 10) as number}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default ContentBuilder;
