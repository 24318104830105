import React, { useState } from 'react';
import ApiService from '../../services/ApiService';
import Snackbars, { EAlertType } from '../common/Snackbars';

function ResendEmail(props) {
  const [disableButton, setDisableButton] = useState(false);
  const [alertOptions, setAlertOptions] = useState({ isOpen: false, message: '', type: EAlertType.INFO });
  const onAlertCloseHandler = () => {
    setAlertOptions({ isOpen: false, message: '', type: EAlertType.INFO });
  };
  return (
    <>
      <button
        className="bg-red-400 hover:bg-red-300 rounded text-white p-2 pl-4 pr-4"
        disabled={disableButton}
        onClick={() => {
          setDisableButton(true);
          ApiService.resendEmail({
            transaction: {
              reference_id: props.transaction.payment_reference,
              id: props.transaction.id,
              client_id: props.clientId,
              product_id: props.transaction.product_id,
              user_name: props.transaction.firstname + props.transaction.lastname,
              user_id: props.transaction.user_id,
              total: props.transaction.payment_amount,
              price: props.transaction.itemprice,
              txn_time: props.transaction.created_date,
              paymentmethod: props.transaction.payment_method,
              to: props.transaction.email,
              product: props.transaction.product,
            },
            clientId: props.clientId,
          })
            .then((data) => {
              setDisableButton(false);
              setAlertOptions({ isOpen: true, message: 'Resend Email success!', type: EAlertType.SUCCESS });
            })
            .catch((err) => {
              setDisableButton(false);
              setAlertOptions({ isOpen: true, message: 'Please try again!', type: EAlertType.ERROR });
            });
        }}
      >
        <p className="font-semibold text-xs">Resend Mail</p>
      </button>
      {alertOptions.isOpen && (
        <Snackbars isOpen={alertOptions.isOpen} message={alertOptions.message} type={alertOptions.type} openCloseHandler={onAlertCloseHandler} />
      )}
    </>
  );
}

export default ResendEmail;
