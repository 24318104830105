import axios from 'axios';

const axiosConfig = {
  headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` },
};

export enum EAUDIT_TYPE {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum EAUDIT_COLLEACTION {
  FORMS = 'FORMS',
  CONTENTS = 'CONTENTS',
  WATCH_PARTY = 'WATCH_PARTY',
  RELATIONS = 'RELATIONS',
  RELATIONAL_CONTENTS = 'RELATIONAL_CONTENTS',
}

/*
 * @param {EAUDIT_TYPE} auditType
 * @param {EAUDIT_COLLEACTION} collection
 * @param {string} oldValue
 * @param {string} newValue
 * Description: This function is used to create audit log for any changes made in the system
 * @returns {void}
 */
export const auditLogger = async (auditType: EAUDIT_TYPE, collection: EAUDIT_COLLEACTION, oldValue: string, newValue: string) => {
  const url = process.env.REACT_APP_API_URL + '/audit';
  const timestamp = new Date().toISOString();
  const userDetails = JSON.parse(window.localStorage.getItem('user') as string);
  const user = JSON.stringify({
    id: userDetails.jti,
    email: userDetails.email,
    firstname: userDetails.given_name,
    lastname: userDetails.family_name,
  });

  const data = {
    type: auditType,
    collection: collection,
    timestamp: timestamp,
    user: user,
    oldValue: oldValue,
    newValue: newValue,
  };

  axios
    .post(url, data, axiosConfig)
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
};
