import React, { useEffect, useState } from 'react';
import { Button, TextField, Checkbox } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import Snackbars, { EAlertType } from '../common/Snackbars';
import { auditLogger, EAUDIT_COLLEACTION, EAUDIT_TYPE } from '../../utils/Utils';
import parse from 'html-react-parser';
import { cloneDeep } from 'lodash';

const API_URL = process.env.REACT_APP_API_URL;

const ProductMapper = () => {
  const { clientId, productId } = useParams() as { clientId: string | number; productId: string };
  const history = useHistory();

  const [programeLists, setProgrameLists] = React.useState<Record<string, any>[]>([]);
  const [sessionIds, setSessionIds] = React.useState<string[]>([]);
  const [product, setProduct] = React.useState<Record<string, any>>({});
  const [filteredProgrameLists, setFilteredProgrameLists] = React.useState<Record<string, any>[]>([]);
  const [alertOptions, setAlertOptions] = useState({ isOpen: false, message: '', type: EAlertType.INFO });

  useEffect(() => {
    axios
      .get(`${API_URL}/contents/${productId}`)
      .then((response) => {
        if (response.data) {
          setProduct(response.data);
          if (response.data.sessionIds) {
            setSessionIds(response.data.sessionIds.split(','));
          }
        }
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${API_URL}/contents?tenant=${clientId}&collection=Programes`)
      .then((response) => {
        setProgrameLists(response.data.data);
        setFilteredProgrameLists(response.data.data);
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  }, [alertOptions, clientId]);

  const onClickHandler = (url: string) => {
    history.push(url + `${clientId}/` + '');
  };

  const onAlertCloseHandler = () => {
    setAlertOptions({ isOpen: false, message: '', type: EAlertType.INFO });
  };

  const onSaveClickHandler = () => {
    const cloneProduct = cloneDeep(product);
    cloneProduct.preview['sessionIds'] = sessionIds.join(',');
    axios
      .put(`${API_URL}/contents/${productId}`, cloneProduct)
      .then((response) => {
        setAlertOptions({ isOpen: true, message: 'Changes saved!', type: EAlertType.SUCCESS });
        // setTimeout(() => {
        //   history.push(`/contents/${clientId}`);
        // }, 2000);
      })
      .catch((error) => {
        setAlertOptions({ isOpen: true, message: 'Failed to update! Try again', type: EAlertType.ERROR });
        console.log(error);
      });
    // AUDIT LOG
    auditLogger(EAUDIT_TYPE.UPDATE, EAUDIT_COLLEACTION.CONTENTS, JSON.stringify(product), JSON.stringify(cloneProduct));
  };

  const onSearchChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const searchValue = event.target.value;
    if (searchValue === '') {
      setFilteredProgrameLists(programeLists);
    } else {
      const filteredroomLists = programeLists.filter((roomLists) => {
        const obj = Object.keys(roomLists).some((key) => {
          if (roomLists[key].toString().toLowerCase().includes(searchValue.toLowerCase())) {
            return roomLists;
          }
        });
        if (obj) return true;
      });
      setFilteredProgrameLists(filteredroomLists);
    }
  };

  const onCheckboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, sessionId: string) => {
    const sessions = [...sessionIds];
    if (event.target.checked) {
      sessions.push(sessionId);
    } else {
      sessions.splice(sessions.indexOf(sessionId), 1);
    }
    setSessionIds(sessions);
  };

  return (
    <div className="container-wrapper">
      <div className="container-title search-container" style={{ marginTop: '-20px' }}>
        Product Mapping - {product?.title?.replace(/(<[^>]+>|<[^>]>|<\/[^>]>)/g, '')}
      </div>
      <div className="container-title search-container">
        <TextField
          id="outlined-basic"
          style={{ width: '400px' }}
          label="Search"
          variant="outlined"
          name="search"
          onChange={(e) => {
            onSearchChangeHandler(e);
          }}
        />
        <div style={{ display: 'flex', paddingLeft: '20px' }}>
          <div style={{ paddingRight: '10px' }}>
            <Button variant="contained" color="inherit" onClick={() => onClickHandler('/contents/')}>
              Cancel
            </Button>
          </div>
          <div>
            <Button variant="contained" color="inherit" onClick={onSaveClickHandler}>
              Save
            </Button>
          </div>
        </div>
      </div>
      <div className="watch-party-form-container">
        {alertOptions.isOpen && (
          <Snackbars isOpen={alertOptions.isOpen} message={alertOptions.message} type={alertOptions.type} openCloseHandler={onAlertCloseHandler} />
        )}
        <div className="room-listing-details">
          <div className="room-listing-form-element">
            <label>Programme Id</label>
          </div>
          <div className="room-listing-form-element">
            <label>Programme Title</label>
          </div>
          <div className="room-listing-form-element">
            <label>Programme Category</label>
          </div>
          <div className="room-listing-form-element">
            <label>Action</label>
          </div>
        </div>
        <div className="container-underline"></div>

        {filteredProgrameLists.length > 0 &&
          filteredProgrameLists.map((row, index) => (
            <div key={index}>
              <div className="room-listing-details room-listing-details-item">
                <div className="room-listing-form-element">
                  <label>{row.id}</label>
                </div>
                <div className="room-listing-form-element">
                  <label>{parse(row.title)}</label>
                </div>
                <div className="room-listing-form-element">
                  <label>{row.category}</label>
                </div>
                <div className="room-listing-form-element action-button">
                  <Checkbox
                    checked={sessionIds.includes(row.id)}
                    onChange={(e) => onCheckboxChangeHandler(e, row.id)}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </div>
              </div>
              <div className="container-underline"></div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProductMapper;
