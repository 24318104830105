import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, InputLabel, Select, MenuItem, DialogActions } from '@material-ui/core';
import { Input, Button } from '@StixNext/mojito-ui';
import axios from 'axios';
import { EAlertType } from '../common/Snackbars';
import { auditLogger, EAUDIT_TYPE, EAUDIT_COLLEACTION } from '../../utils/Utils';
import { cloneDeep } from 'lodash';
const API_URL = process.env.REACT_APP_API_URL;

interface Props {
  fieldOpen: boolean;
  fieldName: string | number;
  title: string | number;
  type: string | number;
  selectValue: string;
  relationName: string | number;
  relationType: string | number;
  isRequired: boolean | string;
  relations: Record<string, any>[];
  isSuccessCall: boolean;
  selectedForm: Record<string, any>;
  collectionType: string;
  selectLabel: string;
  handleFieldClose: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFieldNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTitleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTypeChange: (event: any) => void;
  handleSelectValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRelationNameChange: (event: any) => void;
  handleRelationTypeChange: (event: any) => void;
  handleRequiredChange: (event: any) => void;
  setSuccessCall: React.Dispatch<React.SetStateAction<boolean>>;
  setFieldOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertOptions: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      message: string;
      type: EAlertType;
    }>
  >;
  handleSelectLabelChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormFields = (props: Props) => {
  const {
    fieldOpen,
    fieldName,
    title,
    type,
    selectValue,
    relationName,
    relationType,
    isRequired,
    relations,
    isSuccessCall,
    selectedForm,
    collectionType,
    selectLabel,
    handleRelationNameChange,
    handleSelectValueChange,
    handleFieldClose,
    handleFieldNameChange,
    handleTypeChange,
    handleRelationTypeChange,
    handleRequiredChange,
    setSuccessCall,
    setFieldOpen,
    setAlertOptions,
    handleTitleChange,
    handleSelectLabelChange,
  } = props;

  const addNewFields = () => {
    const oldData = cloneDeep(selectedForm);
    const data = selectedForm;
    let url = '';
    if (collectionType === 'collection_model') {
      url = `${API_URL}/forms/`;
    } else {
      url = `${API_URL}/relations/`;
    }

    data.properties[fieldName] = {
      type: type === 'editor' || type === 'dateTime' || type === 'select' || type === 'file' || type === 'textArea' ? 'string' : type,
      title: title ?? fieldName,
      titleText: fieldName,
      default: type === 'boolean' ? false : type === 'select' ? 'Select an option' : '',
      customEditor: type === 'editor',
      textArea: type === 'textArea',
    };
    if (type === 'dateTime') {
      data.properties[fieldName].format = 'date-time';
    }
    if (type === 'file') {
      data.properties[fieldName].format = 'data-url';
    }
    if (type === 'select') {
      const options = ('Select an option,' + selectValue).split(',');
      const labels = ('Select an option,' + selectLabel).split(',');
      data.properties[fieldName].enum = options;
      data.properties[fieldName].enumNames = labels;
    }
    const index = data.required.indexOf(fieldName);
    if (index > -1) {
      data.required.splice(index, 1);
    }
    if (isRequired === 'True' && data.required) {
      data.required.push(fieldName);
    }
    if (type === 'relation') {
      data.properties[fieldName].relationType = relationType;
      data.properties[fieldName].relationName = relationName;
    }
    axios
      .put(url + data._id, data)
      .then((response) => {
        setAlertOptions({ isOpen: true, message: 'Changes saved!', type: EAlertType.SUCCESS });
        setFieldOpen(false);
        setSuccessCall(!isSuccessCall);
      })
      .catch((error) => {
        setAlertOptions({ isOpen: true, message: 'Failed to update! Try again', type: EAlertType.ERROR });
        console.log(error);
      });
    // AUDIT LOG
    auditLogger(EAUDIT_TYPE.UPDATE, EAUDIT_COLLEACTION.FORMS, JSON.stringify(oldData), JSON.stringify(data));
  };

  return (
    <Dialog fullWidth open={fieldOpen} onClose={handleFieldClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add Fields</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Adding fields to the collection! <p style={{ color: 'red' }}>(Name should be in camelCase)</p>
        </DialogContentText>
        <div>
          <Input
            id="textbox"
            type="input"
            textarea={false}
            cols="100"
            rows="10"
            disabled={false}
            error={false}
            value={fieldName}
            onChange={handleFieldNameChange}
            validationCaption="Enter a valid name"
            placeholder="Name"
          />
        </div>
        <div style={{ paddingTop: '20px' }}>
          <Input
            id="textbox"
            type="input"
            textarea={false}
            cols="100"
            rows="10"
            disabled={false}
            error={false}
            value={title}
            onChange={handleTitleChange}
            validationCaption="Enter a valid name"
            placeholder="Label"
          />
        </div>
        <div style={{ paddingTop: '20px' }}>
          <InputLabel id="demo-simple-select-label">Field type</InputLabel>
          <Select style={{ width: '100%' }} labelId="demo-simple-select-label" id="demo-simple-select" value={type} onChange={handleTypeChange}>
            <MenuItem value="string">Text</MenuItem>
            <MenuItem value="textArea">Text Area</MenuItem>
            <MenuItem value="number">Number</MenuItem>
            <MenuItem value="dateTime">Date Time</MenuItem>
            <MenuItem value="file">Image File</MenuItem>
            <MenuItem value="boolean">Boolean</MenuItem>
            <MenuItem value="editor">Editor</MenuItem>
            <MenuItem value="select">Dropdown</MenuItem>
            <MenuItem value="relation">Relational Model</MenuItem>
          </Select>
        </div>
        {type === 'select' && (
          <>
            <div style={{ paddingTop: '20px' }}>
              <Input
                id="textbox"
                type="input"
                textarea={false}
                cols="100"
                rows="10"
                disabled={false}
                error={false}
                value={selectValue}
                onChange={handleSelectValueChange}
                validationCaption="Enter a valid name"
                placeholder="Add dropdown value as comma seperated text"
              />
            </div>
            <div style={{ paddingTop: '20px' }}>
              <Input
                id="textbox"
                type="input"
                textarea={false}
                cols="100"
                rows="10"
                disabled={false}
                error={false}
                value={selectLabel}
                onChange={handleSelectLabelChange}
                validationCaption="Enter a valid name"
                placeholder="Add dropdown label as comma seperated text"
              />
            </div>
          </>
        )}
        {type === 'relation' && (
          <div style={{ paddingTop: '20px' }}>
            <InputLabel id="demo-simple-select-label">Relation Name</InputLabel>
            <Select
              style={{ width: '100%' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={relationName}
              onChange={handleRelationNameChange}
            >
              {relations.map((relation) => (
                <MenuItem value={relation.title}>{relation.title}</MenuItem>
              ))}
            </Select>
          </div>
        )}
        {type === 'relation' && (
          <div style={{ paddingTop: '20px' }}>
            <InputLabel id="demo-simple-select-label">Relation Type</InputLabel>
            <Select
              style={{ width: '100%' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={relationType}
              onChange={handleRelationTypeChange}
            >
              <MenuItem value="one_to_one">One to One</MenuItem>
              <MenuItem value="one_to_many">One to Many</MenuItem>
            </Select>
          </div>
        )}
        <div style={{ paddingTop: '20px' }}>
          <InputLabel id="demo-simple-select-label">Is Required?</InputLabel>
          <Select
            style={{ width: '100%' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={isRequired}
            onChange={handleRequiredChange}
          >
            <MenuItem value="True">True</MenuItem>
            <MenuItem value="False">False</MenuItem>
          </Select>
        </div>
      </DialogContent>
      <DialogActions>
        <Button primary onClick={handleFieldClose} color="primary">
          Cancel
        </Button>
        <Button primary onClick={addNewFields} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormFields;
