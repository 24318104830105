import React, { useState } from 'react';
import { withTheme } from '@rjsf/core';
import { Theme as FluentUITheme } from '@rjsf/fluent-ui';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Input } from '@StixNext/mojito-ui';
import FileWidget from './FileWidget';

import SampleSchema from '../../utils/schema.json';
import '../../styles/editor.css';

const FILE_UPLOAD_URL = process.env.REACT_APP_FILE_UPLOAD_URL;

function Editor(props) {
  // const Form = withTheme(MaterialUITheme);]
  const [removedImages, setRemovedImages] = useState([{ name: '', value: '' }]);
  Object.keys(props.data.properties).forEach((eachProps) => {
    if (props?.data?.properties?.[eachProps]?.format === 'data-url') {
      if (Object.keys(props?.formData)?.length > 0) {
        if (!props.data?.required?.includes(eachProps)) {
          if (props?.formData?.[eachProps] === '' || props?.formData?.[eachProps] === 'undefined') {
            props.formData[eachProps] = 'data:image/gif;base64,';
          } else {
            if (Object.keys(props?.formData)?.length > 0) {
              if (!props?.formData?.[eachProps]?.includes('data:image/gif;base64,')) {
                props.formData[eachProps] = 'data:image/gif;base64,' + props.formData[eachProps];
              }
            }
          }
        } else {
          if (props?.formData?.[eachProps] !== '' || props?.formData?.[eachProps] !== 'undefined') {
            if (!props?.formData?.[eachProps]?.includes('data:image/gif;base64,')) {
              props.formData[eachProps] = 'data:image/gif;base64,' + props.formData[eachProps];
            }
          }
        }
      }
    }
  });

  const Form = withTheme(FluentUITheme);
  const schema = props.data || SampleSchema;

  const htmlEditor = {
    'ui:widget': (props) => (
      <div>
        <h2>{props.schema.title ?? props.schema.titleText}</h2>
        <CKEditor
          editor={ClassicEditor}
          data={props.value}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log('Editor is ready to use!', editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            props.onChange(data);
          }}
          onBlur={(event, editor) => {
            console.log('Blur.', editor);
          }}
          onFocus={(event, editor) => {
            console.log('Focus.', editor);
          }}
        />
      </div>
    ),
  };

  const FilePreviewWidget = (props) => {
    if (!props.value) {
      return <div>No file uploaded.</div>;
    }
    return (
      <div>
        <h2>{props.schema.title ?? props.schema.titleText} </h2>
        <img
          src={props.value.replace('data:image/gif;base64,', '', 'undefined')}
          style={Object.assign({ width: '100%', height: 200 }, props.style)}
        ></img>
      </div>
    );
  };

  function base64MimeType(encoded) {
    let result = '';

    if (typeof encoded !== 'string') {
      return result;
    }

    let mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
      result = mime[1];
    }

    return result;
  }

  const handleRemoveImg = (data) => {
    if (Object.keys(props?.formData)?.length > 0) {
      props.formData[data.title] = 'data:image/gif;base64,';
      // setRemovedImages([...removedImages, { name: data.title, value: props.formData[data.title] }]);
    }
  };

  const updateFormData = (title, data) => {
    if (Object.keys(props?.formData)?.length > 0) {
      props.formData[title] = 'data:image/gif;base64,' + data;
    }
  };

  const FileInputAndPreviewWidget = (props) => {
    const output: JSX.Element[] = [];

    if (props.value && props.value !== 'data:image/gif;base64,') {
      output.push(<FilePreviewWidget key="preview" {...props} style={{ marginBottom: 10 }} />);
    }

    output.push(
      <FileWidget
        key="file"
        {...props}
        value={undefined}
        handleRemoveImg={handleRemoveImg}
        onChange={(v) => {
          // if (!['image/png', 'image/jpeg', 'image/jpg'].includes(base64MimeType(v))) {
          //   window.alert('Uploaded file must be a png');
          // } else {
          updateFormData(props.schema.titleText, v);
          props.onChange('data:image/gif;base64,' + v);
          // }
        }}
      />,
    );

    return output;
  };

  const input = {
    'ui:widget': (props) => (
      <Input
        id="textbox"
        type="input"
        textarea={false}
        cols="100"
        rows="10"
        disabled={false}
        error={false}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
        validationCaption="Enter a valid name"
        placeholder="Name"
      />
    ),
  };

  const uiSchema = {
    'ui:options': {
      label: true,
    },
  };

  Object.keys(schema.properties).forEach((scheme) => {
    if (schema.properties[scheme].type === 'string' && schema.properties[scheme].format === 'string') {
      uiSchema[scheme] = input;
    }
    if (schema.properties[scheme].customEditor) {
      uiSchema[scheme] = htmlEditor;
    }
    if (schema.properties[scheme].textArea) {
      uiSchema[scheme] = {
        'ui:widget': 'textarea',
      };
    }
  });

  function ObjectFieldTemplate(props) {
    return (
      <div className="form-wrapper">
        <div className="form-title">{props.title}</div>
        <div>{props.description}</div>
        {props.properties.map((element) => {
          return <div style={{ width: '90%' }}>{element.content}</div>;
        })}
      </div>
    );
  }

  const onSubmit = (e: any) => {
    if (Object.keys(props.formData).length > 0) {
      let result = {};
      Object.keys(e.formData).forEach((data) => {
        if (e.formData[data] !== undefined) {
          if (typeof e.formData[data] === 'string') {
            result[data] = e.formData[data].replace('data:image/gif;base64,', '').replace(FILE_UPLOAD_URL, '');
          } else {
            result[data] = e.formData[data];
          }
        }
      });
      props.onSubmit(result, true);
    } else {
      let result = {};
      Object.keys(e.formData).forEach((data) => {
        if (e.formData[data] !== undefined) {
          if (typeof e.formData[data] === 'string') {
            result[data] = e.formData[data].replace('data:image/gif;base64,', '').replace(FILE_UPLOAD_URL, '');
          } else {
            result[data] = e.formData[data];
          }
        }
      });
      props.onSubmit(result, false);
    }
  };

  const onError = (data) => {
    console.log(data, 'error');
  };

  let widgets;
  widgets = { FileWidget: FileInputAndPreviewWidget };
  return (
    <div style={{ margin: '20px' }}>
      <Form
        widgets={widgets}
        schema={schema}
        uiSchema={uiSchema}
        ObjectFieldTemplate={ObjectFieldTemplate}
        formData={props.formData}
        onSubmit={onSubmit}
        onError={onError}
        liveValidate={false}
      ></Form>
    </div>
  );
}

export default Editor;
