import keycloak from './keycloak';

export function AuthorizedFunction(roles) {
  const isAuthorized = () => {
    if (keycloak && roles) {
      return roles.some((r) => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }

    return false;
  };

  return isAuthorized();
}

export const getAuthorizedClient = (roles, clients) => {
  if (roles) {
    if (roles.includes('DCH_SUPER_ADMIN')) {
      return [...clients, ...[{ label: 'Admin Settings', value: 'ADMIN_SETTINGS' }]];
    } else if (roles.length === 1 && roles.includes('DCH_CS')) {
      return clients;
    } else if (roles.length === 1 && roles.includes('DCH_FINANCE')) {
      return clients;
    } else if (roles.length === 1 && roles.includes('DCH_CONTENT_ADMIN')) {
      return clients;
    } else if (roles.length === 1 && roles.includes('DCH_ADMIN')) {
      return clients;
    } else if (roles.includes('DCH_ADMIN') || roles.includes('DCH_CS') || roles.includes('DCH_FINANCE')) {
      return clients.filter((client) => roles.includes(client.value));
    }
  }
};
