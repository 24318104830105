import React from 'react';
import { Route } from 'react-router-dom';
import { AuthorizedFunction } from './AuthorizedFunction';

export function PrivateRoute({ component: Component, roles, restrictedTo, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return AuthorizedFunction(restrictedTo) ? (
          <Component {...props} roles={roles} />
        ) : (
          <div
            style={{
              height: '100vh',
              width: '100vw',
            }}
          />
        );
      }}
    />
  );
}
