import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import DeleteAction from './DeleteAction';
import clsx from 'clsx';
import { EAlertType } from './Snackbars';
import axios from 'axios';
import { auditLogger, EAUDIT_COLLEACTION, EAUDIT_TYPE } from '../../utils/Utils';
const API_URL = process.env.REACT_APP_API_URL;

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {},
  accordionContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  accordionTitle: {},
  accordionAction: {},
  aTag: {
    textDecoration: 'none !important',
    color: 'black',
    '&:hover': {
      color: '#2882d6',
      textDecoration: 'none !important',
      cursor: 'pointer',
    },
  },
  isActive: {
    color: '#2882d6',
  },
}));

interface ICustomAccordionProps {
  name: string;
  title: string;
  type: string;
  items: Record<string, any>[];
  expanded: string;
  selectedForm: string;
  allowDelete?: boolean;
  handleChange: (panel: string) => (_: React.ChangeEvent<HTMLInputElement>, newExpanded: boolean) => void;
  handleSelectedForm: (index: string, type: string, name: string) => void;
  setAlertOptions?: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      message: string;
      type: EAlertType;
    }>
  >;
}
const getUrl = (formId: string, collectionType: string) => {
  let url = '';
  if (collectionType === 'collection_type') {
    url = `${API_URL}/forms/`;
  } else {
    url = `${API_URL}/relations/`;
  }
  return url + formId;
};
function CustomAccordion(props: ICustomAccordionProps) {
  const classes = useStyles();
  const { items, expanded, name, title, type, selectedForm, allowDelete, handleChange, handleSelectedForm, setAlertOptions } = props;
  const handleAccordionChange = (panel: string) => {
    handleChange(panel);
  };
  const onClick = (index: string, type: string, name: string) => {
    handleSelectedForm(index, type, name);
  };
  const onDeleteClickHandler = (url: string, deletedData: Record<string, any>) => {
    axios
      .delete(url)
      .then((response) => {
        if (setAlertOptions) setAlertOptions({ isOpen: true, message: 'Changes saved!', type: EAlertType.SUCCESS });
        window.location.reload();
      })
      .catch((error) => {
        if (setAlertOptions) setAlertOptions({ isOpen: true, message: 'Failed to update! Try again', type: EAlertType.ERROR });
        console.log(error);
      });
    // AUDIT LOG
    auditLogger(EAUDIT_TYPE.DELETE, EAUDIT_COLLEACTION.FORMS, JSON.stringify(deletedData), JSON.stringify({}));
  };
  return (
    <Accordion className="accordion" square expanded onChange={() => handleAccordionChange(name)}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography>{title}</Typography>
      </AccordionSummary>
      {items.map((form, index) => (
        <AccordionDetails>
          <div className={classes.accordionContainer}>
            <div className={classes.accordionTitle}>
              <Typography>
                <a
                  className={clsx(classes.aTag, selectedForm === form._id ? classes.isActive : '')}
                  href="#"
                  onClick={() => onClick(form._id, type, form.title)}
                >
                  {form.titleText ?? form.title}
                </a>
              </Typography>
            </div>
            {allowDelete && (
              <div className={classes.accordionAction}>
                <DeleteAction onDeleteClickHandler={onDeleteClickHandler} url={getUrl(form._id, type)} deletedData={form} />
              </div>
            )}
          </div>
        </AccordionDetails>
      ))}
    </Accordion>
  );
}

export default CustomAccordion;
