import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import '../../styles/formBuilder.css';
// Custom components
import { Button, Input } from '@StixNext/mojito-ui';
import Breadcrumb from '../common/Breadcrumb';
import CustomAccordion from '../common/CustomAccordion';
import { useHistory, useParams } from 'react-router-dom';
import Collection from './Collection';
import axios from 'axios';
import FormFields from './FormFields';
import Snackbars, { EAlertType } from '../common/Snackbars';
import DeleteAction from '../common/DeleteAction';
import { auditLogger, EAUDIT_COLLEACTION, EAUDIT_TYPE } from '../../utils/Utils';
import { cloneDeep } from 'lodash';
const API_URL = process.env.REACT_APP_API_URL;

// axios.defaults.baseURL = API_URL;
// axios.defaults.headers.common.Authorization =
//   'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJBRjRLTE9DS0tGTW83eGZqdGNGcEJTZU45QWJPOXVXMXo0ZkFyYUdCdkVjIn0.eyJleHAiOjE2MTQ4ODE4MzUsImlhdCI6MTYxNDg4MTIzNSwianRpIjoiZGVmODAzZTUtNWNjYS00ZDEyLWE5ZWEtZTcxMGI2YzA2Y2UyIiwiaXNzIjoiaHR0cHM6Ly9ib3VuY2VyLnN0aXhjbG91ZHRlc3QuY29tL2F1dGgvcmVhbG1zL2VUaWNrZXRTZXJ2aWNlIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6IjVjMTEyZWVkLTBlMmQtNGQxMS05MjU0LWNiOGRlYmY4ZjUxMiIsInR5cCI6IkJlYXJlciIsImF6cCI6ImFwaS1qd3QtY2xpZW50Iiwic2Vzc2lvbl9zdGF0ZSI6ImExMTUwZDlhLTlmYzktNGE4Yi1iNzgzLTBmYWZkOWI1NWE0MiIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsIm5hbWUiOiJhcGkgand0IiwicHJlZmVycmVkX3VzZXJuYW1lIjoiYXBpand0IiwiZ2l2ZW5fbmFtZSI6ImFwaSIsImZhbWlseV9uYW1lIjoiand0In0.CmfnATPd9V2BYKCk4VNuekZU8ZPf4u3hPUkWw0zUcMIGZKa44beb-t-U05in4_vo2b4trK3INVvlZLAJ5MPp7altp8TmPhc6MNxjPFwyujmePCPtY7H1QDW_AYNCzWovpNls1QggWEinvXVZHP_YZS2bW2pgMSfcH3DbKIQ11XJgCwLVHmT9w5A7qOsyYw_tT48NfGmavdF-g_ko8gNYIZ2ZzFAuZpniSYZ_xnrRbUfbfM3pwH-zosQykkctcND_7xOWBGZKLUasT9aO3CGtCKs-0KWHPTbPHnoh-6tyb4AaNLkrwquIHKfaBDDKLAjpDPpMEBS92q176b0hq4rv3A';
// axios.defaults.headers.post['Content-Type'] = 'application/json';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#5c5c5c',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {},
  paperElement: {
    margin: '20px',
    border: '1px solid',
    borderRadius: '6px',
  },
  accordionDiv: {
    width: '20%',
    float: 'left',
    border: '1px solid black',
    minHeight: '100vh',
  },
  bodyDiv: {
    minHeight: '100vh',
    margin: '20px',
    display: 'flex',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 999999,
  },
  createGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '10px',
    marginRight: '20px',
  },
  createContent: {
    border: '1px solid black',
    width: '78%',
    minHeight: '100vh',
    marginLeft: '20px',
  },
  mainContent: {
    display: 'flex',
  },
  tableContainer: {
    margin: '10px',
  },
  aTag: {
    textDecoration: 'none !important',
    color: 'black',
    '&:hover': {
      color: 'blue',
      textDecoration: 'none !important',
      cursor: 'pointer',
    },
  },
}));

function FormBuilder(props) {
  const { roles } = props;
  let { tenantId } = useParams() as { tenantId: string | number };
  const [expanded, setExpanded] = useState('panel1');
  const [forms, setForms] = useState<Record<string, any>[]>([]);
  const [relations, setRelations] = useState<Record<string, any>[]>([]);
  const [selectedForm, setSelectedForm] = useState<Record<string, any>>({});
  const [selectValue, setSelectValue] = useState('');
  const [selectLabel, setSelectLabel] = useState('');
  const [collectionType, setCollectionTypeChange] = useState('collection_model');
  const [relationType, setRelationType] = useState('one_to_one');
  const [fieldName, setFieldName] = useState('');
  const [type, setType] = useState('Text');
  const [title, setTitle] = useState('');
  const [relationName, setRelationName] = useState('');
  const [isRequired, setRequired] = useState('True');
  const [open, setOpen] = useState(false);
  const [fieldOpen, setFieldOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isSuccessCall, setSuccessCall] = useState(false);
  const [alertOptions, setAlertOptions] = useState({ isOpen: false, message: '', type: EAlertType.INFO });

  const classes = useStyles();
  let history = useHistory();

  const onAlertCloseHandler = () => {
    setAlertOptions({ isOpen: false, message: '', type: EAlertType.INFO });
  };

  const handleRelationTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRelationType(event.target.value);
  };

  const handleRelationNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRelationName(event.target.value);
  };

  const handleChange = (panel: string) => (_: React.ChangeEvent<HTMLInputElement>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : 'false');
  };

  const handleSelectedForm = (index: string, type: string) => {
    let selectedData: Record<string, any> = {};
    if (type === 'collection_type') {
      selectedData = forms.find((form) => form._id === index) as Record<string, any>;
      setCollectionTypeChange('collection_model');
    } else {
      selectedData = relations.find((relation) => relation._id === index) as Record<string, any>;
      setCollectionTypeChange('relation_model');
    }
    setSelectedForm(selectedData);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFieldOpen = () => {
    setType('Text');
    setFieldName('');
    setTitle('');
    setFieldOpen(true);
  };

  const handleFieldClose = () => {
    setFieldOpen(false);
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value);
  };

  const handleSelectValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectValue(event.target.value);
  };

  const handleSelectLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectLabel(event.target.value);
  };

  const handleRequiredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequired(event.target.value);
  };

  const deleteFields = (key: string) => {
    const oldData = cloneDeep(selectedForm);
    const data = selectedForm;
    const index = data.required.indexOf(key);
    if (index > -1) {
      data.required.splice(index, 1);
    }
    delete data.properties[key];
    let url = '';
    if (collectionType === 'collection_model') {
      url = `${API_URL}/forms/`;
    } else {
      url = `${API_URL}/relations/`;
    }
    axios
      .put(url + data._id, data)
      .then(() => {
        setForms(forms);
        setAlertOptions({ isOpen: true, message: 'Changes saved!!', type: EAlertType.SUCCESS });
        setFieldOpen(false);
        setSuccessCall(!isSuccessCall);
      })
      .catch((error) => {
        setAlertOptions({ isOpen: true, message: 'Failed to update! Try again', type: EAlertType.ERROR });
        console.log(error);
      });
    // AUDIT LOG
    auditLogger(EAUDIT_TYPE.CREATE, EAUDIT_COLLEACTION.FORMS, JSON.stringify(oldData), JSON.stringify(data));
  };

  const editFields = (key: string) => {
    const data = selectedForm.properties[key];
    if (data.type === 'relation') {
      setRelationType(data.relationType);
      setRelationName(data.titleText);
    }
    if (data.enum) {
      setType('select');
      setSelectValue(data.enum.filter((eachData) => eachData !== 'Select an option').toString());
      if (data.enumNames) {
        setSelectLabel(data.enumNames.filter((eachData) => eachData !== 'Select an option').toString());
      }
    } else {
      setSelectValue('');
      setSelectLabel('');
      if (data.customEditor) {
        setType('editor');
      } else if (data.format === 'data-url') {
        setType('file');
      } else if (data.textArea) {
        setType('textArea');
      } else {
        setType(data.type);
      }
    }
    setFieldName(data.titleText);
    setTitle(data.title);
    if (selectedForm.required && selectedForm.required.includes(data.titleText)) {
      setRequired('True');
    } else {
      setRequired('False');
    }
    setFieldOpen(true);
  };

  const handleFieldNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldName(event.target.value);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  useEffect(() => {
    axios
      .get(`${API_URL}/forms?tenant=${tenantId}`)
      .then((response) => {
        setForms(response.data.data);
      })
      .catch((error) => {
        console.log(error, 'error');
      });
    axios
      .get(`${API_URL}/relations?tenant=${tenantId}`)
      .then((response) => {
        setRelations(response.data.data);
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  }, [isSuccessCall, tenantId]);

  const path = [
    {
      text: 'Home',
      url: '/',
    },
    {
      text: 'CMS',
      url: '/cms',
    },
  ];

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const redirectHandler = () => {
    history.push('/contents/' + tenantId);
  };

  return (
    <div>
      <Breadcrumb path={path} title="Collections" />
      {alertOptions.isOpen && (
        <Snackbars isOpen={alertOptions.isOpen} message={alertOptions.message} type={alertOptions.type} openCloseHandler={onAlertCloseHandler} />
      )}
      <div className={classes.paperElement}>
        <Paper variant="outlined" elevation={0}>
          <div className={classes.createGroup}>
            <div style={{ paddingRight: '20px' }}>
              <Button primary onClick={redirectHandler}>
                Show Contents
              </Button>
            </div>
            <Button primary onClick={handleOpen}>
              Add Collections
            </Button>
            <Collection
              open={open}
              tenantId={tenantId}
              isSuccessCall={isSuccessCall}
              handleClose={handleClose}
              setForms={setForms}
              setOpen={setOpen}
              setRelations={setRelations}
              setSuccessCall={setSuccessCall}
              setAlertOptions={setAlertOptions}
            />
          </div>
          <div className={classes.bodyDiv}>
            <div className={classes.accordionDiv}>
              <CustomAccordion
                name="panel1"
                title="Collections"
                type="collection_type"
                items={forms}
                expanded={expanded}
                selectedForm={selectedForm._id}
                allowDelete={roles.includes('DCH_SUPER_ADMIN')}
                handleChange={handleChange}
                handleSelectedForm={handleSelectedForm}
                setAlertOptions={setAlertOptions}
              />
              <CustomAccordion
                name="panel2"
                title="Relational Models"
                type="relation_type"
                items={relations}
                expanded={expanded}
                selectedForm={selectedForm._id}
                allowDelete={roles.includes('DCH_SUPER_ADMIN')}
                handleChange={handleChange}
                handleSelectedForm={handleSelectedForm}
                setAlertOptions={setAlertOptions}
              />
            </div>
            <div className={classes.createContent}>
              <div className={classes.createGroup}>
                <Button primary onClick={handleFieldOpen}>
                  Add Fields
                </Button>
                <FormFields
                  fieldOpen={fieldOpen}
                  fieldName={fieldName}
                  title={title}
                  type={type}
                  selectValue={selectValue}
                  selectLabel={selectLabel}
                  relationName={relationName}
                  relationType={relationType}
                  isRequired={isRequired}
                  relations={relations}
                  isSuccessCall={isSuccessCall}
                  selectedForm={selectedForm}
                  collectionType={collectionType}
                  handleFieldClose={handleFieldClose}
                  handleFieldNameChange={handleFieldNameChange}
                  handleTypeChange={handleTypeChange}
                  handleSelectValueChange={handleSelectValueChange}
                  handleRelationNameChange={handleRelationNameChange}
                  handleRelationTypeChange={handleRelationTypeChange}
                  handleRequiredChange={handleRequiredChange}
                  setSuccessCall={setSuccessCall}
                  setFieldOpen={setFieldOpen}
                  setAlertOptions={setAlertOptions}
                  handleTitleChange={handleTitleChange}
                  handleSelectLabelChange={handleSelectLabelChange}
                />
              </div>
              <div className={classes.tableContainer}>
                <TableContainer component={Paper}>
                  <Table size="medium" aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Field Type</StyledTableCell>
                        <StyledTableCell>Required</StyledTableCell>
                        <StyledTableCell>Edit</StyledTableCell>
                        <StyledTableCell>Delete</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {forms.length > 0 &&
                        Object.keys(selectedForm).length > 0 &&
                        Object.entries(selectedForm.properties as Record<string, any>)
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map(([key, value]) => (
                            <StyledTableRow key={key}>
                              <StyledTableCell component="th" scope="row">
                                {selectedForm._id}
                              </StyledTableCell>
                              <StyledTableCell>{value.titleText}</StyledTableCell>
                              <StyledTableCell>{value.type}</StyledTableCell>
                              <StyledTableCell>{selectedForm.required && selectedForm.required.includes(key) ? 'True' : 'False'}</StyledTableCell>
                              <StyledTableCell>
                                <EditOutlinedIcon onClick={() => editFields(key)} />
                              </StyledTableCell>
                              <StyledTableCell>
                                <DeleteAction onDeleteClickHandler={deleteFields} url={key} />
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={
                    ((forms.length > 0 && Object.keys(selectedForm).length > 0 && Object.entries(selectedForm.properties).length) ?? 10) as number
                  }
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default FormBuilder;
