import React, { useEffect, useState } from 'react';

import { ITableProps, kaReducer, Table } from 'ka-table';
import { DataType, FilteringMode, SortingMode } from 'ka-table/enums';
import { DispatchFunc } from 'ka-table/types';
import { useParams } from 'react-router-dom';
import ApiService from '../../services/ApiService';
import { CSVLink } from 'react-csv';
import Button from '@material-ui/core/Button';
import { Download } from 'react-feather';

const OPTION_KEY = 'finance-list-store';

const columns = [
  { key: 'id', title: 'Id', style: { width: 120 }, dataType: DataType.String },
  { key: 'txn_type', title: 'Txn Type', style: { width: 100 }, dataType: DataType.String },
  { key: 'txn_id', title: 'Txn Id', style: { width: 100 }, dataType: DataType.String },
  { key: 'created_date', title: 'Created Date', style: { width: 220 }, dataType: DataType.Date },
  { key: 'amount', title: 'Sales Amount', style: { width: 100 }, dataType: DataType.Number },
  { key: 'total_amount', title: 'Paid Amount', style: { width: 100 }, dataType: DataType.Number },
  { key: 'booking_charge', title: 'Service Fee', style: { width: 100 }, dataType: DataType.Number },
  { key: 'client_card_rate', title: 'Card Commission Rate', style: { width: 100 }, dataType: DataType.Number },
  { key: 'client_charges', title: 'Card Commission', style: { width: 100 }, dataType: DataType.Number },
  { key: 'mdr', title: 'MDR', style: { width: 100 }, dataType: DataType.Number },
  { key: 'bank_charges', title: 'Merchant Discount', style: { width: 100 }, dataType: DataType.Number },
  { key: 'bank_pays', title: 'Net Amount', style: { width: 100 }, dataType: DataType.Number },
  { key: 'product_id', title: 'Product Id', style: { width: 225 }, dataType: DataType.String },
  { key: 'payment_type', title: 'Payment Type', style: { width: 100 }, dataType: DataType.String },
  { key: 'payment_method', title: 'Payment Method', style: { width: 100 }, dataType: DataType.String },
  { key: 'payment_method_detail', title: 'Credit Card', style: { width: 150 }, dataType: DataType.String },
  { key: 'payment_gateway', title: 'Payment Gateway', style: { width: 150 }, dataType: DataType.String },
  { key: 'source', title: 'Source', style: { width: 100 }, dataType: DataType.String },
];

const defaultOption: ITableProps = {
  columns,
  //editingMode: EditingMode.Cell,
  rowKeyField: 'id',
  sortingMode: SortingMode.Single,
  paging: {
    enabled: true,
    pageIndex: 0,
    pageSize: 10,
  },
  filteringMode: FilteringMode.FilterRow,
  format: ({ column, value }) => {
    switch (column.dataType) {
      case DataType.Date:
        return value && value.toLocaleString('en-SG');
      case DataType.Number:
        return value && value.toFixed(2);
      default:
        break;
    }
  },
};

const tablePropsInit: ITableProps = { ...defaultOption, ...JSON.parse(localStorage.getItem(OPTION_KEY) || '0') };

const FinanceReconcile: React.FC = () => {
  const [collections, setCollections] = React.useState([]);
  let { clientId } = useParams() as { clientId: string | number };
  const [tableProps, changeTableProps] = useState(tablePropsInit);
  const dispatch: DispatchFunc = (action) => {
    changeTableProps((prevState: ITableProps) => {
      const newState = kaReducer(prevState, action);
      const { data, ...settingsWithoutData } = newState;
      localStorage.setItem(OPTION_KEY, JSON.stringify(settingsWithoutData));
      return newState;
    });
  };
  useEffect(() => {
    ApiService.loadCollectionsData({ clientId: clientId }).then((data) => {
      setCollections(data.collection);
    });
  }, [clientId]);
  tablePropsInit.data = collections;
  return (
    <>
      <Table
        {...tableProps}
        childComponents={{
          dataRow: {
            elementAttributes: ({ rowData }) => ({
              style: {
                backgroundColor: rowData.txn_type === 'sale' ? 'rgba(0, 255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)',
              },
              title: `${rowData.name}: ${rowData.score}`,
            }),
          },
        }}
        dispatch={dispatch}
      />
      {collections && collections.length > 0 && (
        <CSVLink
          data={collections}
          // headers={tableProps.columns.map(c => ({label: c.title, key: c.key}))}
          filename={clientId + '-collections-data.csv'}
          enclosingCharacter={''}
          separator={';'}
        >
          <Button className="bg-teal-500 hover:bg-red-300 rounded text-white p-2 pl-4 pr-4 mt-2" startIcon={<Download />}>
            CSV
          </Button>
        </CSVLink>
      )}
    </>
  );
};

export default FinanceReconcile;
