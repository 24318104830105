const configData = {
  default: {
    settings: {
      storage_keys: [
        { page: 'OfferCodes', key: 'offercode-list-store' },
        { page: 'SalesList', key: 'sales-list-stores' },
        { page: 'FinanceReconcile', key: 'finance-list-store' },
      ],
    },
  },
  production: {
    bouncer: '/keycloak.json',
    resource: 'DCHAdmin',
    routes: {
      base: '/',
      root: '/admin/',
      client: '/admin/client/',
      clientwithparams: '/admin/client/:clientId/',
      sales: '/admin/client/:clientId/sales',
      finance: '/admin/client/:clientId/financereconcile',
      offercode: '/admin/client/:clientId/offercode',
      settings: '/admin/client/:clientId/settings',
      reports: '/admin/client/:clientId/reports',
    },
  },
  staging: {
    bouncer: '/keycloak-uat.json',
    resource: 'DCHAdmin',
    routes: {
      base: '/',
      root: '/admin/',
      client: '/admin/client/',
      clientwithparams: '/admin/client/:clientId/',
      sales: '/admin/client/:clientId/sales',
      finance: '/admin/client/:clientId/financereconcile',
      offercode: '/admin/client/:clientId/offercode',
      settings: '/admin/client/:clientId/settings',
      reports: '/admin/client/:clientId/reports',
    },
  },
  development: {
    bouncer: '/keycloak-uat.json',
    resource: 'DCHAdmin',
    routes: {
      base: '/',
      root: '/admin/',
      client: '/admin/client/',
      clientwithparams: '/admin/client/:clientId/',
      sales: '/admin/client/:clientId/sales',
      finance: '/admin/client/:clientId/financereconcile',
      offercode: '/admin/client/:clientId/offercode',
      settings: '/admin/client/:clientId/settings',
      reports: '/admin/client/:clientId/reports',
    },
  },
};

let config = configData[process.env.REACT_APP_ENV];
config.default = configData['default'];
export default config;
