import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { IGuestDetails } from './RoomCreation';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  open: boolean;
  guests: IGuestDetails[];
  isEdit: boolean;
  isSendDisabled: boolean;
  handleClose: () => void;
  onSubmit: (isEdit: boolean) => void;
}

const RoomConfirmation = (props: Props) => {
  const { open, guests, isEdit, isSendDisabled, onSubmit, handleClose } = props;
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CloseIcon onClick={handleClose} />
        </div>
        <DialogContentText>You've added the list of recipients. You may send invitation once it is confirmed or continue to edit.</DialogContentText>
        <div>
          {guests
            .filter((item) => item.isSendEmail && item.isSendEmail == true)
            .map((guest, index) => {
              return <p key={index}>{guest.email}</p>;
            })}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="send-email">
          <Button style={{ marginRight: '20px', width: '100px' }} variant="outlined" onClick={handleClose}>
            Edit
          </Button>
          <Button style={{ width: '100px' }} variant="contained" color="inherit" disabled={isSendDisabled} onClick={() => onSubmit(isEdit)}>
            Send
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default RoomConfirmation;
