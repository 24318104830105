import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { dataURItoBlob } from './utils';
import axios from 'axios';

const FILE_UPLOAD_URL = process.env.REACT_APP_FILE_UPLOAD_URL;

function addNameToDataURL(dataURL, name) {
  return dataURL.replace(';base64', `;name=${encodeURIComponent(name)};base64`);
}

const processFile = async (file) => {
  const { name, size, type } = file;
  let formData = new FormData();

  formData.append('multipartFile', file);
  formData.append('tenant', 'SISTIC');
  // uploading file
  let uploadedUrl = '';
  await axios
    .post(FILE_UPLOAD_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      uploadedUrl = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onerror = reject;
    reader.onload = (event) => {
      resolve({
        dataURL: addNameToDataURL(event.target.result, name),
        uploadedUrl,
        name,
        size,
        type,
      });
    };
    reader.readAsDataURL(file);
  });
};

function processFiles(files) {
  return Promise.all([].map.call(files, processFile));
}

function FilesInfo(props) {
  const { filesInfo } = props;
  if (filesInfo.length === 0) {
    return null;
  }
  return (
    <ul className="file-info">
      {filesInfo.map((fileInfo, key) => {
        const { name, size, type, uploadedUrl } = fileInfo;
        return (
          <li key={key}>
            <strong>{uploadedUrl}</strong> ({type}, {size} bytes)
          </li>
        );
      })}
    </ul>
  );
}

function extractFileInfo(dataURLs) {
  return dataURLs
    .filter((dataURL) => typeof dataURL !== 'undefined')
    .map((dataURL) => {
      const { blob, name } = dataURItoBlob(dataURL);
      return {
        name: name,
        size: blob.size,
        type: blob.type,
      };
    });
}

class FileWidget extends React.Component {
  constructor(props) {
    super(props);
    const { value } = props;
    const values = Array.isArray(value) ? value : [value];
    this.state = { values, filesInfo: extractFileInfo(values) };
    this.myRef = React.createRef();
  }

  //   shouldComponentUpdate(nextProps, nextState) {
  //     return shouldRender(this, nextProps, nextState);
  //   }

  onChange = (event) => {
    const { multiple, onChange } = this.props;
    processFiles(event.target.files).then((filesInfo) => {
      const state = {
        values: filesInfo.map((fileInfo) => fileInfo.dataURL),
        filesInfo,
      };
      const uploadedUrl = filesInfo.map((fileInfo) => fileInfo.uploadedUrl);
      this.setState(state, () => {
        if (multiple) {
          onChange(uploadedUrl);
        } else {
          onChange(uploadedUrl[0]);
        }
      });
    });
  };

  render() {
    const { multiple, id, readonly, disabled, autofocus, options } = this.props;
    const { filesInfo } = this.state;
    return (
      <div>
        <h2>{this.props.schema.title ?? this.props.schema.titleText}</h2>
        <p>
          <input
            ref={this.myRef}
            id={id}
            type="file"
            disabled={readonly || disabled}
            onChange={this.onChange}
            defaultValue=""
            autoFocus={autofocus}
            multiple={multiple}
            accept={options.accept}
          />
        </p>
        <FilesInfo filesInfo={filesInfo} />
        <div
          key={this.props.schema.titleText}
          id={id}
          style={{
            cursor: 'pointer',
            border: '2px solid black',
            borderRadius: '2px',
            width: '30%',
            marginTop: '2px',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
          onClick={(e) => {
            this.props.handleRemoveImg({ title: this.props.schema.titleText });
          }}
        >
          Remove Image
        </div>
      </div>
    );
  }
}

FileWidget.defaultProps = {
  autofocus: false,
};

if (process.env.NODE_ENV !== 'production') {
  FileWidget.propTypes = {
    multiple: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    autofocus: PropTypes.bool,
  };
}

export default FileWidget;
