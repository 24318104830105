import React, { useState } from 'react';
import ApiService from '../../services/ApiService';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Snackbars, { EAlertType } from '../common/Snackbars';

function RefundAction(props) {
  const [disableButton, setDisableButton] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [alertOptions, setAlertOptions] = useState({ isOpen: false, message: '', type: EAlertType.INFO });
  if (!props.iscard) {
    return <div></div>;
  }

  const onAlertCloseHandler = () => {
    setAlertOptions({ isOpen: false, message: '', type: EAlertType.INFO });
  };

  if ((!props.refundId || props.refundId === 'NA') && props.currentStatus !== 'voided') {
    const onCloseClickHandler = () => {
      setIsDialogOpen(false);
    };
    const onConfirm = () => {
      setIsDialogOpen(false);
      onButtonClickHandler();
    };
    const onClickHandler = () => {
      setIsDialogOpen(true);
    };
    const onButtonClickHandler = () => {
      setDisableButton(true);
      ApiService.refundTxn({
        txnid: props.txnToRefund,
        clientId: props.clientId,
      })
        .then((data) => {
          setDisableButton(false);
          setAlertOptions({ isOpen: true, message: 'Refund success! \n\nTransaction is ' + data.status, type: EAlertType.SUCCESS });
        })
        .catch((err) => {
          setDisableButton(false);
          setAlertOptions({ isOpen: true, message: 'Please try again!', type: EAlertType.ERROR });
        });
    };
    return (
      <>
        <button
          className="bg-red-400 hover:bg-red-300 rounded text-white p-2 pl-4 pr-4"
          disabled={disableButton}
          onClick={() => {
            onClickHandler();
          }}
        >
          <p className="font-semibold text-xs">Refund</p>
        </button>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={isDialogOpen}
          onClose={onCloseClickHandler}
          message="Are you sure to initiate the Refund?"
          action={
            <>
              <Button color="secondary" size="small" onClick={onConfirm}>
                Yes
              </Button>
              <Button color="secondary" size="small" onClick={onCloseClickHandler}>
                No
              </Button>
            </>
          }
        />
        {alertOptions.isOpen && (
          <Snackbars isOpen={alertOptions.isOpen} message={alertOptions.message} type={alertOptions.type} openCloseHandler={onAlertCloseHandler} />
        )}
      </>
    );
  } else if (props.currentStatus === 'voided') {
    return (
      <button className="bg-blue-500 hover:bg-gray-500 rounded text-white p-2 pl-4 pr-4 ">
        <p className="font-semibold text-xs ">Voided</p>
      </button>
    );
  } else {
    return (
      <button
        className="bg-blue-500 hover:bg-gray-500 rounded text-white p-2 pl-4 pr-4 "
        onClick={() => {
          ApiService.txnInfo({
            txnid: props.refundId,
            clientId: props.clientId,
          })
            .then((data) => {
              setAlertOptions({ isOpen: true, message: 'Refund for ' + props.txnToRefund + ', status is ' + data.status, type: EAlertType.SUCCESS });
            })
            .catch((err) => {
              setAlertOptions({ isOpen: true, message: 'Please try again!', type: EAlertType.ERROR });
            });
        }}
      >
        <p className="font-semibold text-xs ">Refund Info</p>
      </button>
    );
  }
}

export default RefundAction;
