import React, { useState } from 'react';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

interface Props {
  url: string;
  onDeleteClickHandler: (url: string, deletedData: Record<string, any>) => void;
  deletedData?: Record<string, any>;
}

const DeleteAction = (props: Props) => {
  const { url, onDeleteClickHandler, deletedData = {} } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const onCloseClickHandler = () => {
    setIsDialogOpen(false);
  };
  const onDelete = () => {
    setIsDialogOpen(false);
    onDeleteClickHandler(url, deletedData);
  };
  const onClickHandler = () => {
    setIsDialogOpen(true);
  };
  return (
    <>
      <DeleteOutlinedIcon onClick={() => onClickHandler()} />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isDialogOpen}
        onClose={onCloseClickHandler}
        message="Are you sure to delete?"
        action={
          <>
            <Button color="secondary" size="small" onClick={onDelete}>
              Yes
            </Button>
            <Button color="secondary" size="small" onClick={onCloseClickHandler}>
              No
            </Button>
          </>
        }
      />
    </>
  );
};

export default DeleteAction;
