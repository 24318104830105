import React, { useState } from 'react';
import ApiService from '../../services/ApiService';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

function getModalStyle() {
  return {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    top: '20%',
    left: '36%',
    columnGap: '20px',
    flexFlow: 'column',
    rowGap: '20px',
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function UpdateEmail(props) {
  const [disableButton, setDisableButton] = useState(false);
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [updatedEmail, setUpdatedEmail] = React.useState();
  const [updatedEmailError, setUpdatedEmailError] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateEmailAddress = (e) => {
    e.preventDefault();
    if (updatedEmail) {
      let emailOrId = props.transaction.user_id.substring(props.transaction.user_id.lastIndexOf(':') + 1, props.transaction.user_id.length);
      if (emailOrId === undefined) {
        emailOrId = updatedEmail;
      } else {
        emailOrId = props.transaction.user_id.replace(emailOrId, updatedEmail);
      }
      setDisableButton(true);
      ApiService.updateEmail({
        transaction: {
          orderid: props.transaction.payment_reference,
          transactionid: props.transaction.id,
          emailID: updatedEmail,
          userid: emailOrId,
        },
        clientId: props.clientId,
      })
        .then((data) => {
          setDisableButton(false);
          handleClose();
          alert('Update Email success!');
          // window.location.reload(true);
        })
        .catch((err) => {
          setDisableButton(false);
          alert(err);
        });
    } else {
      setUpdatedEmailError(true);
    }
  };

  return (
    <>
      <button className="bg-red-400 hover:bg-red-300 rounded text-white p-2 pl-4 pr-4" disabled={disableButton} onClick={handleOpen}>
        <p className="font-semibold text-xs">Update Mail</p>
      </button>
      <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <div style={modalStyle} className={classes.paper}>
          <h6>Update Email Address</h6>
          <input
            className="top-element w-full text-gray-700"
            id="mail"
            type="text"
            placeholder="Email"
            style={{
              border: updatedEmailError ? '1px solid red' : '1px solid #e2e8f0',
              height: '33px',
              borderRadius: '5px',
              paddingLeft: '10px',
            }}
            onChange={(event) => {
              setUpdatedEmail(event.currentTarget.value);
            }}
          />
          <div
            style={{
              display: 'flex',
              /* align-items: flex-end; */
              width: '100%',
              justifyContent: 'flex-end',
              columnGap: '20px',
            }}
          >
            <button
              className="bg-blue-400 hover:bg-blue-300 rounded text-white p-2 pl-4 pr-4"
              disabled={disableButton}
              onClick={(e) => updateEmailAddress(e)}
            >
              <p className="font-semibold text-xs">Submit</p>
            </button>
            <button className="bg-gray-400 hover:bg-gray-300 rounded text-white p-2 pl-4 pr-4" disabled={disableButton} onClick={handleClose}>
              <p className="font-semibold text-xs">Close</p>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UpdateEmail;
