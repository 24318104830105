import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

type TPath = { text: string; url: string };
interface IBreadcrumbProps {
  path: TPath[];
  title: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function handleClick(event: React.ChangeEvent<HTMLInputElement>) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

function Breadcrumb(props: IBreadcrumbProps) {
  const classes = useStyles();
  const { path, title } = props;
  return (
    <div className={classes.root}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {path.map((eachPath) => (
          <Link color="inherit" href={eachPath.url} onClick={() => handleClick}>
            {eachPath.text}
          </Link>
        ))}
        <Typography color="textPrimary">{title}</Typography>
      </Breadcrumbs>
    </div>
  );
}

export default Breadcrumb;
